import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-8415_WQHD/NavButtons';
import CompareIndoor from 'components/Products/Compare/CompareIndoorFlyout';
import TableFeatures from 'components/Indoor_Cameras/IN-8415_WQHD/TableFeatures';
import IndoorData from 'components/Products/Flyouts/Graphs/IndoorGraphsFlyout';
import SmartCards from 'components/Products/Flyouts/Cards/8401Cards';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8415 2k+ WQHD",
  "path": "/Indoor_Cameras/IN-8415_WQHD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8415 WQHD Indoor IP Camera",
  "image": "../P_SearchThumb_IN-8001HD.png",
  "social": "/images/Search/P_SearchThumb_IN-8415WQHD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8415WQHD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='IN-8415 2k+ WQHD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-8415 2k+ WQHD Indoor IP Camera' image='/images/Search/P_SearchThumb_IN-8415WQHD.webp' twitter='/images/Search/P_SearchThumb_IN-8415WQHD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8415_WQHD/' locationFR='/fr/Indoor_Cameras/IN-8415_WQHD/' crumbLabel="IN-8415" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-8415-2k-wqhd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-8415-2k-wqhd",
        "aria-label": "in 8415 2k wqhd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8415 2k+ WQHD`}</h1>
    <CompareIndoor mdxType="CompareIndoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7abb7faf02435394971623231573da19/5caea/INSTAR_IN-8415_WQHD.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACY0lEQVQozxXCy1MScQAA4J9a107N1K2Z1Gk8dHV6k5PVpS7qODbEmJipISzIS1igdVteuywPeSzIYxeXBURhwYXlNWoQpeOWU4dm+nOavvnA6fGxJP2ULn5cnEln/UGn0SwXSwccx9JZOkXnmMztW6PPpl7gLmwnFJyeGF+clvldzsMsu21QAZENS9LlYHBeE5pHgrhb4NHEfnI3ySapgNtNOD2P7z2Yn53HnagZUqvkbziGydJMJhw1Lc+B80q8U95r1srIQeuul51ICGP7J6PV3ni1N8aId5LVySQfKtUxo1qtmI2HI5F4Smi0okFSM/MQ9MvUl2Loq3i4RBXBGjZiT4JIDVACCPLAVwZYAdhSRKVNbS2H9HKhygv1ttgQKURNQnOgWwi2WbzXOpL70teU8A17/CZZuk4Jw5+5EVt6ZCs2pMHxsrhPWr63aucXv1rNTkPsxDBj2PwW1DOuo8SntliHiSBdyJROB3Yy9uSjFVgTw/bksD4AlhzuXCWPG74JRenyj/T770mvv2Nd9mlnAL+LlkLmTreLEV4fidr8/oNy54MDBSvYVYi4AhFA6cCLAoebc5iqwQS6FZYLwFGLImp9B6oJhPPpe/2+wmB5rbJMKtRy2P8c9gAlOqSAhxb04NWqO8dTqC4GK/O+zTyh5bxa1qthXBugmtjOEoZG+3jBiMhWNC/1yCMd+tTklhldUya3TIvcV0KRPO+zayK2pYwHynqhrFfD/K8GfNxRidkoTLdHmEsha4E0FklTgTDmCQPr0e95dFnPptv0PrC1mEZWU+h6Gl2jsXXatUE7Vf8Ajv890ScDeQMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7abb7faf02435394971623231573da19/e4706/INSTAR_IN-8415_WQHD.avif 230w", "/en/static/7abb7faf02435394971623231573da19/d1af7/INSTAR_IN-8415_WQHD.avif 460w", "/en/static/7abb7faf02435394971623231573da19/7f308/INSTAR_IN-8415_WQHD.avif 920w", "/en/static/7abb7faf02435394971623231573da19/c91b9/INSTAR_IN-8415_WQHD.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7abb7faf02435394971623231573da19/a0b58/INSTAR_IN-8415_WQHD.webp 230w", "/en/static/7abb7faf02435394971623231573da19/bc10c/INSTAR_IN-8415_WQHD.webp 460w", "/en/static/7abb7faf02435394971623231573da19/966d8/INSTAR_IN-8415_WQHD.webp 920w", "/en/static/7abb7faf02435394971623231573da19/ef2bd/INSTAR_IN-8415_WQHD.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7abb7faf02435394971623231573da19/81c8e/INSTAR_IN-8415_WQHD.png 230w", "/en/static/7abb7faf02435394971623231573da19/08a84/INSTAR_IN-8415_WQHD.png 460w", "/en/static/7abb7faf02435394971623231573da19/c0255/INSTAR_IN-8415_WQHD.png 920w", "/en/static/7abb7faf02435394971623231573da19/5caea/INSTAR_IN-8415_WQHD.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7abb7faf02435394971623231573da19/c0255/INSTAR_IN-8415_WQHD.png",
              "alt": "INSTAR IN-8415 2k+ WQHD",
              "title": "INSTAR IN-8415 2k+ WQHD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`INSTAR IN-8415 2k+ WQHD Pan&Tilt Night Vision Surveillance Camera (LAN/WLAN) Small but indoor camera with 850nm infrared LEDs, PIR sensor and SD card. 3 second pre-recording before alarm. Smarthome compatibility thanks to Homekit, Alexa Skill, IFTTT and CGI & MQTT Interface. The compact design of this camera was completely new developed and makes it ideal for discreet surveillance task.`}</p>
    <p>{`The IN-8415 2k+ WQHD surveillance camera is ready for implementation into existing security system. But also as a stand-alone device it offers you a number of alarm features like `}<Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link">{`Email & Push Notification`}</Link>{`, two-way communication and an alarm siren. The WPS function allows you to connect the camera to your WiFi network with a press of a button. Accessing your camera from the internet is even easier. The Point-2-Point connection can be established by scanning a QR code with our smartphone app. The free `}<strong parentName="p">{`InstarVision`}</strong>{` mobile app is available for `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/"
      }}>{`Android`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/"
      }}>{`iOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/"
      }}>{`Windows Metro`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/"
      }}>{`Windows Phone`}</a>{`.`}</p>
    <IndoorData mdxType="IndoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The sensitivity of the integrated Panasonic `}<strong parentName="p">{`Pyroelectric Sensor`}</strong>{` has been significantly improved thanks to a unique slit design of the pyroelectric elements. The separated sensing areas prevent thermal crosstalk between the single sensing elements. This makes a reliable detection possible even if the temperature difference between the background (e.g. floor /wall) and the target object (human body, car engine, etc) is small (around 4°C).`}</p>
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Integrated PIR heat detector for advanced motion detection`}</li>
      <li parentName="ul"><strong parentName="li">{`STARVIS`}</strong>{` 1440p `}<Link to="/Indoor_Cameras/IN-8415_WQHD/Technical_Specifications/" mdxType="Link">{`SONY CMOS Sensor`}</Link></li>
      <li parentName="ul">{`Built-in microphone incl. noise detection with adjustable max. level, software audio noise and echo reduction`}</li>
      <li parentName="ul">{`Built-in PIR motion detector to avoid false alarms`}</li>
      <li parentName="ul">{`Powerful 850nm IR night vision LEDs (activated manually or triggered by the integrated PIR sensor)`}</li>
      <li parentName="ul">{`Suitable for wall and ceiling mounting`}</li>
      <li parentName="ul">{`MicroSD memory cards up to 256GB possible. (32GB already installed)`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`WiFi`}</Link>{` 5GHz / 2.4GHz, Wi‑Fi 5 (802.11ac) for 433 Mbit/s networks with WPA2/WPA3 security`}</li>
      <li parentName="ul">{`DHCP and UPnP support for an automatic network integration`}</li>
      <li parentName="ul">{`Integrated personal, free `}<Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link">{`DDNS ("Internet") Address`}</Link>{` to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/System/User/" mdxType="Link">user and user rights management</Link> and simultaneous login for multiple users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/1440p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/1440p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> {" "} email notification </Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record 15s alarm videos (+ 3s pre-recording) directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Security cameras need to be able to record, with clear images, in a variety of environments. Sony's `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` image sensors meet this need by providing high-sensitivity performance suitable for low-light and night recordings. `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` is a back-illuminated pixel technology used in CMOS image sensors for security camera applications. It features highly sensitive backlight illuminated sensor pixels, and realize high picture quality in the visible-light and near infrared light regions. The IMX335 `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` Sensor is a diagonal 2.8 inch CMOS active pixel type solid-state image sensor with a square pixel array and 5.14 M effective pixels.`}</p>
    <SmartCards mdxType="SmartCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The SONY `}<strong parentName="p">{`STARVIS`}</strong><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` IMX335 CMOS sensor features a dynamic range up to 72 dB, advanced HDR functions and digital overlap HDR further improve the image quality and contrast behavior allowing for more detailed image analysis. These features when combined with the low dark current performance further improve its performance in applications demanding a high dynamic range.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`*`}</code>{` `}<strong parentName="p">{`STARVIS`}</strong>{` is a trademark of the Sony Corporation. A STARVIS sensor is defined by a sensitivity of `}<em parentName="p">{`2000 mV`}</em>{` or more per `}<em parentName="p">{`1 μm²`}</em>{` when illuminated with a `}<em parentName="p">{`706 cd/m²`}</em>{` light source, `}<em parentName="p">{`F5.6`}</em>{` in `}<em parentName="p">{`1s`}</em>{` accumulation equivalent.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      